import React from 'react';
import RichTextEditor from 'react-rte';
import { ErrMessage } from '../../../../components';

const RichTextEditorExample = ({ inputs, setInputs, error, setError }) => {

  const handleChange = (value) => {
    setInputs({ ...inputs, note: value });

    if (error) setError('');
  };

  return (
    <div className="note-text-editor-wrapper">

      <RichTextEditor
        value={inputs?.note ? inputs.note : RichTextEditor.createEmptyValue()}
        onChange={handleChange}
        placeholder="Write your note here..."
        toolbarConfig={{
          display: [
            'INLINE_STYLE_BUTTONS',
            'BLOCK_TYPE_BUTTONS',
            'BLOCK_ALIGNMENT_BUTTONS',
            'LINK_BUTTONS',
          ],
          INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' },
          ],
          BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' },
          ],
          BLOCK_ALIGNMENT_BUTTONS: [
            { label: 'AL', style: 'ALIGN_LEFT' },
            { label: 'AC', style: 'ALIGN_CENTER' },
            { label: 'AR', style: 'ALIGN_RIGHT' },
          ],
          LINK_BUTTONS: [
            { label: 'Link!', style: 'unstyled', className: 'rte-button rte-button-link' },
            { label: 'Remove link', style: 'unstyled', className: 'rte-button rte-button-link-remove' },
          ],
        }}
      />
      {error &&
        <ErrMessage text={'Note is required'} />
      }
    </div>
  );
};

export default RichTextEditorExample;