

export const mcDocumentEnums = {
  NOA: 'NOA',
  REL: 'REL',
  CARRIER_PACKET: 'CARRIER_PACKET',
  COI: 'COI',
  W9: 'W9',
  COMPANY_PACKET: 'COMPANY_PACKET',
  LOR: 'LOR',
  UCC_FILING: 'UCC_FILING',
  FACTORING_CONTRACT: 'FACTORING_CONTRACT',
  INSURACNE_CERTIFICATE: 'INSURACNE_CERTIFICATE',
  OTHER: 'OTHER',
  BUYOUT_AGREEMENT: 'BUYOUT_AGREEMENT',
  CONDITIONAL_RELEASE: 'CONDITIONAL_RELEASE',
  LETTER_OF_RELEASE: 'LETTER_OF_RELEASE',
}

export const mcUploadDockTypes = [
  { name: 'Notice of Assignment', id:  mcDocumentEnums.NOA },
  { name: 'Release letter', id: mcDocumentEnums.REL },
  { name: 'Carrier Packet', id: mcDocumentEnums.CARRIER_PACKET },
  { name: 'COI', id: mcDocumentEnums.COI },
  { name: 'W-9', id: mcDocumentEnums.W9 },
  { name: 'Company packet', id: mcDocumentEnums.COMPANY_PACKET },
  { name: 'LOR', id: mcDocumentEnums.LOR },
  { name: 'UCC Filing', id: mcDocumentEnums.UCC_FILING },
  { name: 'Factoring Contract', id: mcDocumentEnums.FACTORING_CONTRACT },
  { name: 'Insurance Certificate', id: mcDocumentEnums.INSURACNE_CERTIFICATE },
  { name: 'Other', id: mcDocumentEnums.OTHER },
  { name: 'Buyout Agreement', id: mcDocumentEnums.BUYOUT_AGREEMENT },
  { name: 'Conditional release', id: mcDocumentEnums.CONDITIONAL_RELEASE },
  { name: 'Letter of release', id: mcDocumentEnums.LETTER_OF_RELEASE },
];
