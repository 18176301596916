import axios from "axios";

export const authService = {
    /** Ar Reports */

    getArSummaryService: (info, type) => axios.get('/report', { auth: true, params: {...info} }),


    getArDetailedService: (info, type) => axios.get(type === 'Past Reports' ? '/reports/ar/detail' : '/reports/ar/detail/current', {
        auth: true,
        params: {...info}
    }),

    getArActivityService: () => axios.get('/reports/ar/activity', {auth: true}),

    arApplicationService: (info) => axios.get('/reports/ar/pa', {auth: true, params: {...info}}),
    /** End */

    /** Ap Reports */
    getApDetailedService: (info, type) => axios.get(type === 'Past Reports' ? '/reports/ap/detail' : '/reports/ap/detail/current', {
        auth: true,
        params: {...info}
    }),

    getApSummaryService: (info, type) => axios.get(type === 'Past Reports' ? '/reports/ap/summary' : '/reports/ap/summary/current', {
        auth: true,
        params: {...info}
    }),

    getApActivityService: () => axios.get('/reports/ap/activity', {auth: true}),

    apApplicationService: (info) => axios.get('/reports/ap/pa', {auth: true, params: {...info}}),
    /** End */

};