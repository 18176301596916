export const fileTypeList =[
    {name:'PERSONAL', id:'PERSONAL'},
    {name:'AVATAR', id:'AVATAR'},
    {name:'DOCUMENT', id:'DOCUMENT'},
]


const officeTypeEnums = {
    VOIDED_CHECK: 'VOIDED_CHECK',
    DRIVERS_LICENSE: 'DRIVERS_LICENSE',
    PAYMENT_AUTHORIZATION: 'PAYMENT_AUTHORIZATION',
    AGENTPANDP: 'AGENTPANDP',
    SERVICEAGREEMENT: 'SERVICEAGREEMENT',
    W9: 'W9',
    OTHER: 'OTHER',
}
export const officeDockTypes = [
    {name: 'Agent P&P', id: officeTypeEnums.AGENTPANDP},
    {name: 'Service Agreement', id: officeTypeEnums.SERVICEAGREEMENT},
    {name: 'W9', id: officeTypeEnums.W9},
    {name: 'Other', id: officeTypeEnums.OTHER},
    {name: 'Voided Check', id: officeTypeEnums.VOIDED_CHECK},
    {name: "Driver's License", id: officeTypeEnums.DRIVERS_LICENSE},
    {name: 'Payment Authorization', id: officeTypeEnums.PAYMENT_AUTHORIZATION},
]