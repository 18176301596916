import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions, httpRequestsOnSuccessActions, loadActions, loadAuthActions } from 'store';
import { CsvFile, DateFilters, FullTable, PriceConvertor, TableWrapper, UserInputsDropdown } from 'components';
import { PermissionsList, RolePermission, SavePage, useGlobalStyles, useModal } from 'utils';
import { ACTION_TYPE, RECEIVABLE, receivablesBody, receivablesCsv, receivablesHead } from '../constants';
import { TotalItems } from './core/totalItems';
import { ReceivablesNoteDrawer } from './receivablesNote';

export const Receivables = ({}) => {
  const { loadsList, receivablesInfo, customersList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
    receivablesInfo: state.loads.receivablesInfo,
    customersList: state.customers.customersList,
  }));
  const dispatch = useDispatch();
  const classes = useGlobalStyles();
  const history = useHistory();
  const info = history?.location?.state;
  const { open } = useModal();
  const body = receivablesBody(open);

  useEffect(() => {
    dispatch(customerActions.getCustomers({ status: 'ACTIVE' }));
  }, []);

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    const receivablesInfo = renderParams();
    delete receivablesInfo.skip;
    delete receivablesInfo.limit;
    delete receivablesInfo.department;

    dispatch(loadActions.getReceivablesInfo(receivablesInfo));
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo, department: RECEIVABLE }, loader));
  };

  const handleSubmit = async (info, it) => {
    if (it === 'verify') {
      dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', info.id));
      try {
        await loadAuthActions.verifyBilling(info.id);
        handleGet('noLoad');
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', info.id));
        dispatch(httpRequestsOnSuccessActions.appendSuccess('BILLING_VERIFY'));
      } catch (e) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', info.id));
      }
    }
  };

  const pushPageDetails = (id) => {
    history.push({
      pathname: `/load/${id}`,
      state: { loadParams: { ...info }, parent: '/receivables', detail: 'billing' },
    });
  };

  const handleChange = (value, name) => {
    const params = {
      ...info,
    };

    if (value) {
      params[name] = value.id;
    } else {
      delete params[name];
    }
    SavePage(history, info, { ...params });
  };


  return (
    <div>
      <div className={classes.filtersWrapper}>
        <div className="align-center">
          <DateFilters status={RECEIVABLE} />
          <ReceivablesNoteDrawer/>
          <UserInputsDropdown
            dropdownOptions={customersList?.customers}
            onPass={(option) => handleChange(option, 'customer')}
            selected={info?.customer ? info?.customer : ''}
            noText={'All Customers'}
            firstText={'name'}
          />
        </div>
        {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.EXPORT_RECEIVABLE_CSV?.code]) &&
          <CsvFile params={receivablesCsv} />
        }
      </div>

      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
          <div className={classes.totalWrapper}>
            <div className={classes.centerItem}>
              <p className={classes.totalTitle}>Totals</p>

              <div className={classes.receivablesTotalsBox}>
                <TotalItems
                  text={<PriceConvertor price={receivablesInfo?.current?.amount} />}
                  count={` (${receivablesInfo?.current?.count || 0})`}
                  title={'Current:'}
                  color={'#00C8511A'}
                />
                <TotalItems
                  text={<PriceConvertor price={receivablesInfo?.dueNow?.amount} />}
                  count={` (${receivablesInfo?.dueNow?.count || 0})`}
                  title={'Due Now:'}
                  color={'#438AFE1A'}
                />
                <TotalItems
                  text={<PriceConvertor price={receivablesInfo?.pastDue?.amount} />}
                  count={` (${receivablesInfo?.pastDue?.count || 0})`}
                  title={'Past Due:'}
                  color={'#FFA3301A'}
                />
                <TotalItems
                  text={<PriceConvertor price={receivablesInfo?.urgent?.amount} />}
                  title={'Urgent:'}
                  count={` (${receivablesInfo?.urgent?.count || 0})`}
                  color={'#D725251A'}
                />
              </div>

            </div>
            <div>
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.grandTotal?.amount} />}
                count={` (${receivablesInfo?.grandTotal?.count || 0})`}
                title={'Total: '}
                color={'#E6ECF380'}
                styles={{ marginRight: 0 }}
              />
            </div>
          </div>
        <FullTable
          head={receivablesHead}
          body={body}
          loadingType={ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          handleClick={pushPageDetails}
          noText={'Receivables'}
          handleClickButton={handleSubmit}
        />
      </TableWrapper>
    </div>
  );
};