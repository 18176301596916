/** Create Load */
export const CREATE_LOAD = 'CREATE_LOAD';

export const CREATE_LOAD_BY_TEMPLATE = 'CREATE_LOAD_BY_TEMPLATE';

/** Edit Load */
export const EDIT_LOAD = 'EDIT_LOAD'

export const CANCEL_LOAD = 'CANCEL_LOAD'

/** Get Loads */
export const GET_LOAD_TOTALS = 'GET_LOAD_TOTALS';
export const GET_LOAD_TOTALS_SUCCESS = 'GET_LOAD_TOTALS_SUCCESS';
export const GET_LOADS_BY_STATUS = 'GET_LOADS_BY_STATUS';
export const GET_LOADS_BY_STATUS_SUCCESS = 'GET_LOADS_BY_STATUS_SUCCESS';
export const GET_LOADS_RECEIVABLES = 'GET_LOADS_RECEIVABLES';
export const GET_LOADS_RECEIVABLES_SUCCESS = 'GET_LOADS_RECEIVABLES_SUCCESS';
export const GET_LOADS_PAYABLE = 'GET_LOADS_PAYABLE';
export const GET_LOADS_PAYABLE_SUCCESS = 'GET_LOADS_PAYABLE_SUCCESS';
export const GET_LOADS_BY_PAYABLE = 'GET_LOADS_BY_PAYABLE';
export const GET_LOADS_BY_PAYABLE_SUCCESS = 'GET_LOADS_BY_PAYABLE_SUCCESS';

/** Get Load by id */
export const GET_LOAD_BY_ID = 'GET_LOAD_BY_ID';
export const GET_LOAD_BY_ID_SUCCESS = 'GET_LOAD_BY_ID_SUCCESS';

/** Get Load Customer and Carrier*/
export const GET_LOAD_CUSTOMER = 'GET_LOAD_CUSTOMER';
export const GET_LOAD_CUSTOMER_SUCCESS = 'GET_LOAD_CUSTOMER_SUCCESS';
export const GET_LOAD_CARRIER = 'GET_LOAD_CARRIER';
export const GET_LOAD_CARRIER_SUCCESS = 'GET_LOAD_CARRIER_SUCCESS';

export const GET_CARRIER_LAST_LOAD = 'GET_CARRIER_LAST_LOAD';
export const GET_CARRIER_LAST_LOAD_SUCCESS = 'GET_CARRIER_LAST_LOAD_SUCCESS';

/** Carriers CarrierPayment */
export const SEARCH_REC = 'SEARCH_REC'
export const FILTER_REC = 'FILTER_REC'
export const FILTER_REC_DEFAULT = 'FILTER_REC_DEFAULT'

/** Carrier CarrierPayment */
export const SEARCH_CARR = 'SEARCH_CARR'
export const FILTER_CARR = 'FILTER_CARR'
export const FILTER_CARR_DEFAULT = 'FILTER_CARR_DEFAULT'

/** Carrier CarrierPayment */
export const SEARCH_LOAD = 'SEARCH_LOAD'
export const FILTER_LOAD = 'FILTER_LOAD'
export const FILTER_LOAD_DEFAULT = 'FILTER_LOAD_DEFAULT'

/** Load Transactions */
export const GET_LOAD_TRANSACTIONS = 'GET_LOAD_TRANSACTIONS'
export const GET_LOAD_TRANSACTIONS_SUCCESS = 'GET_LOAD_TRANSACTIONS_SUCCESS'

export const GET_LOAD_CARRIER_TRANSACTIONS = 'GET_LOAD_CARRIER_TRANSACTIONS'
export const GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS = 'GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS'

export const GET_LOAD_OTHER_TRANSACTIONS = 'GET_LOAD_OTHER_TRANSACTIONS'
export const GET_LOAD_OTHER_TRANSACTIONS_SUCCESS = 'GET_LOAD_OTHER_TRANSACTIONS_SUCCESS'
/** Load Payable */
export const SEND_TO_PAYABLE = 'SEND_TO_PAYABLE'
export const APPROVE_PAYABLE = 'APPROVE_PAYABLE'

/** Pasts */
export const GET_PAST_PAYABLE = 'GET_PAST_PAYABLE'
export const GET_PAST_PAYABLE_SUCCESS = 'GET_PAST_PAYABLE_SUCCESS'

export const GET_PAST_BILLS = 'GET_PAST_BILLS'
export const GET_PAST_BILLS_SUCCESS = 'GET_PAST_BILLS_SUCCESS'

export const RE_BILL = 'RE_BILL';
/** Get Customers */
export const REMOVE_SEARCHED_CUSTOMERS = 'REMOVE_SEARCHED_CUSTOMERS';

export const GET_SEARCH_LOAD_CUSTOMERS = 'GET_SEARCH_LOAD_CUSTOMERS';
export const GET_SEARCH_LOAD_CUSTOMERS_SUCCESS = 'GET_SEARCH_LOAD_CUSTOMERS_SUCCESS';

/** Receivable Notes */

export const GET_RECEIVABLES_NOTE_SEARCH = 'GET_RECEIVABLES_NOTE_SEARCH';
export const GET_RECEIVABLES_NOTE = 'GET_RECEIVABLES_NOTE';
export const GET_RECEIVABLES_NOTE_SUCCESS = 'GET_RECEIVABLES_NOTE_SUCCESS';
export const CREATE_RECEIVABLES_NOTE = 'CREATE_RECEIVABLES_NOTE';
export const EDIT_RECEIVABLES_NOTE = 'EDIT_RECEIVABLES_NOTE';
export const DELETE_RECEIVABLES_NOTE = 'DELETE_RECEIVABLES_NOTE';
