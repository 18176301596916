import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {CloseButton, CreateChancel, modalsStyle, SelectInput, ValidationInput} from "components";
import { ErrorText, FindLoad, FindSuccess, useGlobalStyles, useGlobalTextStyles, useModal } from 'utils';
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {receivablesFragments} from "./styles";
import {customerPaymentActions, factoringActions, httpRequestsOnSuccessActions} from "store";
import {paymentMethods} from "./constants";

const ACTION_TYPE = 'CREATE_CUSTOMER_PAYMENT'
export const AddCustomerPayment = ({ afterSuccess }) => {
    const {factoringsList} = useSelector((state) => ({
        factoringsList: state.factorings.factoringsList,
    }));
    const classes = modalsStyle()
    const globalWrappers = useGlobalStyles();
    const globalText = useGlobalTextStyles();
    const customClasses = receivablesFragments();
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState({})
    const [selectType, setSelectType] = useState('FACTORING')
    const { close } = useModal();

    useEffect(() => {
        dispatch(factoringActions.getFactorings({status: 'ACTIVE'}))
    }, []);

    useEffect(() => {
        if (success.length) {
            afterSuccess()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            close()
        }
    }, [success])

    const handleCreate = () => {
        const selType = selectType === 'FACTORING' ? inputs.factoring : true
        if (inputs.pmtDate && inputs.reference && inputs.pmtMethod && inputs.amount && selType) {
            const date = {
                amount: +inputs.amount,
                factoring: inputs.factoring,
                pmtMethod: inputs.pmtMethod,
                reference: inputs.reference,
                pmtDate: moment(inputs.pmtDate).format('YYYY-MM-DD'),
            }
            dispatch(customerPaymentActions.createCustomerPayment(date))
        } else {
            setError(
                !inputs.pmtDate ? 'pmtDate' :
                    !inputs.reference ? 'reference' :
                        !inputs.pmtMethod ? 'pmtMethod' :
                            !inputs.amount ? 'amount' :
                                !inputs.factoring ? 'factoring' :
                                    ''
            )
        }
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),)
        error === e.target.name && setError('')
    }

    const handleChangeType = (e) => {
        setSelectType(e.target.value)
    }

    return (
        <div className={classes.payroll}>

            <div>
                <p className={globalText.modalTitle}>{'Add Customer Payment'}</p>
                <p className={globalText.modalText}>{'To add Customer Payment please, fill out the below fields.'}</p>
                <ValidationInput
                    variant={"outlined"}
                    onChange={handleChange}
                    value={inputs.pmtDate}
                    type={"date"}
                    label={"Date*"}
                    name='pmtDate'
                    typeError={error === 'pmtDate' && ErrorText.field}
                />
                <ValidationInput
                    variant={"outlined"}
                    name={"reference"}
                    label={"Reference Number*"}
                    type={'text'}
                    typeError={error === 'reference' ? ErrorText.field : ''}
                    onChange={handleChange}
                    value={inputs.reference}
                />
                <div className={globalWrappers.flexAble}>
                    <SelectInput
                        style={globalWrappers.simpleInput}
                        name={"pmtMethod"}
                        label={"Payment Method*"}
                        handleSelect={handleChange}
                        value={inputs.pmtMethod}
                        list={paymentMethods}
                        typeError={error === 'pmtMethod' ? ErrorText.field : ''}
                        type={'id'}
                    />
                    <ValidationInput
                        variant={"outlined"}
                        name={"amount"}
                        label={"Amount*"}
                        type={'number'}
                        typeError={error === 'amount' ? ErrorText.field : ''}
                        onChange={handleChange}
                        value={inputs.amount}
                    />
                </div>
                <FormControl className={customClasses.checkbox}>
                    <FormLabel className={customClasses.title} id="demo-row-radio-buttons-group-label">This payment is
                        from:</FormLabel>
                    <RadioGroup
                        onChange={handleChangeType}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="selectType"
                        value={selectType}
                    >
                        <FormControlLabel value="FACTORING" control={<Radio/>} label="Factoring Comapny"/>
                        <FormControlLabel value="CUSTOMER" control={<Radio/>} label="Customer"/>
                    </RadioGroup>
                </FormControl>
                {selectType === 'FACTORING' &&
                <SelectInput
                    name={"factoring"}
                    label={"Factoring Company*"}
                    handleSelect={handleChange}
                    value={inputs.factoring}
                    list={factoringsList?.factorings ? factoringsList.factorings : []}
                    typeError={error === 'factoring' ? ErrorText.field : ''}
                    type={'id'}
                />
                }
                <div className={classes.createChancel}>
                <CreateChancel
                    loader={!!loader.length}
                    classes={globalWrappers.buttonsStyle}
                    create={"Add Payment"}
                    chancel={"Cancel"}
                    onCreate={handleCreate}
                    onClose={() => close()}
                />
                </div>
            </div>
        </div>
    )
}