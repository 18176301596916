import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { navBarStyles } from './style';
import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { GlobalLogo } from 'components';
import { RoutingAllList } from '../../../utils/routers/routers';
import { AccordionRow } from './accordionRow';
import { ListItemRow } from './listItemRow';
import { useSelector } from 'react-redux';

export const LeftBar = ({ handleDrawerClose, open }) => {
  const { loadTotals } = useSelector((state) => ({
    loadTotals: state.loads.loadTotals,
  }));
  const classes = navBarStyles();
  const [accounting, setAccounting] = useState('');
  const creditCount = (loadTotals?.numCreditRequests ? loadTotals?.numCreditRequests : 0) + (loadTotals?.numConsignmentRequest ? loadTotals?.numConsignmentRequest : 0);
  const list = RoutingAllList(creditCount);

  return (
    <div id={'muiAccordion'} className={classes.wrapp}>
      <div
        className={open === false ? classes.transition : classes.transitionOpen}
      >
        <IconButton
          style={{ background: '#1C2331 0% 0% no-repeat padding-box' }}
          className={classes.IconButtonStyle}
          onClick={handleDrawerClose}
        >
          {open === false ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <GlobalLogo />
        <Divider />
        <div className={classes.leftSection} />

        <List className={classes.menuItems}>
          {
            list?.map((i, k) => i && (
              <Fragment key={k}>
                {i?.type === 'accordion' ?
                  <AccordionRow
                    list={i?.list}
                    name={i?.name}
                    activeIcon={i?.activeIcon}
                    passiveIcon={i?.icon}
                    open={open}
                    accounting={accounting}
                    setAccounting={setAccounting}
                    activeCount={i?.activeCount ? i.activeCount : null}
                  />
                  :
                  <ListItemRow
                    list={[{
                      'name': i?.name,
                      'icon': i?.icon,
                      'activeIcon': i?.activeIcon,
                      'path': i?.path,
                    },
                    ]}
                    open={open}
                    setAccounting={() => setAccounting('')}
                  />
                }
              </Fragment>
            ))
          }
        </List>
        <Divider />
      </Drawer>
    </div>
  );
};
