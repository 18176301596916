import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./customerPayment.service";
import {
    ADD_CUSTOMER_PAYMENT_LOAD_INFO,
    CREATE_CUSTOMER_PAYMENT, CREATE_CUSTOMER_PAYMENT_LOAD, DELETE_CUSTOMER_PAYMENT_CUSTOMER, EDIT_CUSTOMER_PAYMENT,
    GET_CUSTOMER_PAYMENT,
    GET_CUSTOMER_PAYMENT_BY_ID, GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD,
    GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS, GET_CUSTOMER_PAYMENT_LOAD, GET_CUSTOMER_PAYMENT_LOAD_SUCCESS,
    GET_CUSTOMER_PAYMENT_SUCCESS, VOID_CUSTOMER_PAYMENT_LOAD,
} from "./customerPayment.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

function* createCustomerPayment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {

        yield call(authService.createCustomerPaymentService, action.payload.body);
        // yield put({
        //     type: GET_CUSTOMER_PAYMENT,
        // });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    }
}

function* editCustomerPayment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editCustomerPaymentService, action.payload.id, action.payload.body,);
        yield put({
            type:GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD,
            payload: {id: action.payload.id}
        })
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type,err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getCustomerPayment({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getCustomerPaymentsService, payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put({
            type: GET_CUSTOMER_PAYMENT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* getCustomerPaymentById({payload, type}) {
    if (payload.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getCustomerPaymentsByIdService, payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put({
            type: GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteCustomer({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteCustomerPayment, payload.id, payload.customerId);

        yield put({
            type:GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD,
            payload: {id: payload.id}
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* addCustomerLoadInfo({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        // yield call(authService.addCustomerLoadService, payload.info );
        // yield put({
        //     type:GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD,
        //     payload: {id: payload.info.id}
        // })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCustomerPaymentByIdNoLoad({payload, type}) {
    try {
        const res = yield call(authService.getCustomerPaymentsByIdService, payload.id);
        yield put({
            type: GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
    }
}

function* createCustomerPaymentLoad({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createCustomerPaymentLoadService, payload?.info, payload?.id);
        yield put({
            type: GET_CUSTOMER_PAYMENT_BY_ID,
            payload: {id: payload.id, load: 'noLoad'},
        });
        yield put({
            type: GET_CUSTOMER_PAYMENT_LOAD,
            payload: {id: payload.id},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCustomerPaymentLoad({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getCustomerPaymentLoadService, payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put({
            type: GET_CUSTOMER_PAYMENT_LOAD_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* voidCustomerPaymentLoad({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.voidCustomerPaymentLoadService, payload?.id, payload?.loadId);
        yield put({
            type: GET_CUSTOMER_PAYMENT_LOAD,
            payload: {id: payload.id},
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

export const watchCustomerPayments = function* watchCustomerPaymentsSaga() {
    yield takeLatest(CREATE_CUSTOMER_PAYMENT, createCustomerPayment);
    yield takeLatest(EDIT_CUSTOMER_PAYMENT, editCustomerPayment);
    yield takeLatest(GET_CUSTOMER_PAYMENT, getCustomerPayment);
    yield takeLatest(GET_CUSTOMER_PAYMENT_BY_ID, getCustomerPaymentById);
    yield takeLatest(GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD, getCustomerPaymentByIdNoLoad);
    yield takeLatest(DELETE_CUSTOMER_PAYMENT_CUSTOMER, deleteCustomer);
    yield takeLatest(ADD_CUSTOMER_PAYMENT_LOAD_INFO, addCustomerLoadInfo);

    yield takeLatest(CREATE_CUSTOMER_PAYMENT_LOAD, createCustomerPaymentLoad);
    yield takeLatest(GET_CUSTOMER_PAYMENT_LOAD, getCustomerPaymentLoad);
    yield takeLatest(VOID_CUSTOMER_PAYMENT_LOAD, voidCustomerPaymentLoad);
};
