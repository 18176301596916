import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { Images, useModal, useSecondaryModal } from 'utils';
import { ReceivableNoteDrawerModal } from './noteDrawerModal';
import { ActiveInactive, SimpleModal } from '../../../../components';

export const ReceivablesNoteDrawer = ({ }) => {
  const [openNotes, setOpenNotes] = useState(false);
  const { openSecondary } = useSecondaryModal({noPadding: true});

  return (
    <div>
      <button
        className='receivable-notes-btn'
        onClick={() => openSecondary( <ReceivableNoteDrawerModal />)}
      >
        <img src={Images.receivableNotes} alt="" />
      </button>



      {/*<SimpleModal*/}
      {/*  handleOpenClose={() => setOpenNotes(false)}*/}
      {/*  openDefault={openNotes}*/}
      {/*  content={*/}
      {/*    <ReceivableNoteDrawerModal*/}
      {/*      handleClose={() => setOpenNotes(false)}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}


      <Drawer
        className={'note-drawer-modal'}
        // anchor={'bottom'}
        open={openNotes}
        onClose={() => setOpenNotes(false)}
      >
        <ReceivableNoteDrawerModal
          handleClose={() => setOpenNotes(false)}
        />
      </Drawer>
    </div>
  );
};