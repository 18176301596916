import React from 'react';
import { DateRow, TextRow } from 'components';
import {
  CarrierTransactionTypes,
  handleGetTypes,
  renderPriceRow,
} from '../../loads/transactions/core/constants';


export const factoredTransactionHead = [
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  {
    name: 'type', title: 'Type',
    filterList: CarrierTransactionTypes,
  },
  { name: '', title: 'Customer Rate', custom: false },
  { name: 'qty', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
];

export const factoredTransactionBody = [
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  {
    rowText: (item) => renderPriceRow(item?.amount, item)
      // <p className={renderColors(item)}>
      //   <PriceRow info={item?.amount} />
      // </p>,
  },
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  {
    rowText: (item) => renderPriceRow(item?.totalAmount, item)
      // <PriceRow info={item?.totalAmount ? item?.totalAmount : 0} />
  },
  { rowText: (item) => <TextRow name={item?.description} textWidth={13} /> },
];


export const ACTION_TYPE = 'GET_LOAD_TRANSACTIONS';



export const CORPORATE = 'CORPORATE';
export const EFS = 'EFS';
export const OFFICE_CHARGE = 'OFFICE_CHARGE';
export const FUEL = 'FUEL';
export const QUICKPAY = 'QUICKPAY';
