import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './assets/styles/allStyles.scss'
import {Provider} from "react-redux";
import {store} from './store'
import {AllModals, initAxiosInterceptors, ModalProvider,  AllSecondaryModals, SecondaryModalProvider} from "utils";
import * as dotenv from 'dotenv'

dotenv.config()
initAxiosInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
          <SecondaryModalProvider>
          <ModalProvider>
              <App />
              <AllModals/>
              <AllSecondaryModals/>
          </ModalProvider>
          </SecondaryModalProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
