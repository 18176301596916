import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './loads.service';
import {
  CREATE_LOAD,
  EDIT_LOAD,
  GET_LOAD_BY_ID,
  GET_LOAD_BY_ID_SUCCESS,
  GET_LOAD_CUSTOMER,
  GET_LOAD_CUSTOMER_SUCCESS,
  GET_LOAD_CARRIER,
  GET_LOAD_CARRIER_SUCCESS,
  FILTER_CARR_DEFAULT,
  GET_LOAD_TRANSACTIONS,
  GET_LOAD_TRANSACTIONS_SUCCESS,
  GET_LOADS_BY_STATUS,
  GET_LOADS_BY_STATUS_SUCCESS,
  SEND_TO_PAYABLE,
  APPROVE_PAYABLE,
  GET_LOADS_BY_PAYABLE,
  GET_LOADS_BY_PAYABLE_SUCCESS,
  GET_LOADS_RECEIVABLES,
  GET_LOADS_RECEIVABLES_SUCCESS,
  GET_PAST_PAYABLE,
  GET_PAST_PAYABLE_SUCCESS,
  GET_PAST_BILLS,
  GET_PAST_BILLS_SUCCESS,
  CANCEL_LOAD,
  GET_SEARCH_LOAD_CUSTOMERS,
  GET_SEARCH_LOAD_CUSTOMERS_SUCCESS,
  RE_BILL,
  GET_CARRIER_LAST_LOAD,
  GET_CARRIER_LAST_LOAD_SUCCESS,
  GET_LOAD_CARRIER_TRANSACTIONS,
  GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS,
  CREATE_LOAD_BY_TEMPLATE,
  GET_LOAD_OTHER_TRANSACTIONS,
  GET_LOAD_OTHER_TRANSACTIONS_SUCCESS,
  GET_LOAD_TOTALS,
  GET_LOAD_TOTALS_SUCCESS,
  GET_RECEIVABLES_NOTE,
  GET_RECEIVABLES_NOTE_SUCCESS,
  CREATE_RECEIVABLES_NOTE,
  EDIT_RECEIVABLES_NOTE,
  DELETE_RECEIVABLES_NOTE,
  GET_RECEIVABLES_NOTE_SEARCH,
  GET_LOADS_PAYABLE,
  GET_LOADS_PAYABLE_SUCCESS,
} from './loads.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { toast } from 'react-toastify';
import { ToastScreen } from '../../components';

/** Create, Edit Load */

function* createLoad({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.createLoadService, payload.body);
    window.location.replace('/loads');
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* createLoadTemplate({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.createLoadTemplateService, payload.id);
    window.location.replace(`/load/${res?.data?.id}`);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* editLoad({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editLoadService, payload.body, payload.id);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* cancelLoad({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.cancelLoadService, payload.id);
    yield put({
      type: GET_LOAD_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    toast(<ToastScreen type={'error'} text={err?.data?.message} />);
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}

/** End */

/** Get Loads */

function* getLoadBbyPayable({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getLoadsByPayableService, payload);
    yield put({
      type: GET_LOADS_BY_PAYABLE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getReceivablesInfo({ payload, type }) {
  try {
    const res = yield call(authService.getReceivablesInfoService, payload);
    yield put({
      type: GET_LOADS_RECEIVABLES_SUCCESS,
      payload: res.data,
    });

  } catch (err) {

  }
}

function* getPayableInfo({ payload, type }) {
  try {
    const res = yield call(authService.getPayableInfoService, payload);
    yield put({
      type: GET_LOADS_PAYABLE_SUCCESS,
      payload: res.data,
    });

  } catch (err) {

  }
}

function* getLoadTotals({}) {
  try {
    const res = yield call(authService.getLoadTotalsService);
    yield put({
      type: GET_LOAD_TOTALS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* getLoadsByStatus({ type, payload }) {
  if (payload.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));

  try {
    const res = yield call(authService.getLoadsByStatusService, payload.params);
    yield put({
      type: GET_LOADS_BY_STATUS_SUCCESS,
      payload: res.data,
    });
    if (payload?.params?.searchKey) {
      yield put(httpRequestsOnLoadActions.removeLoading(payload?.params?.searchKey));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put({
      type: GET_LOADS_BY_STATUS_SUCCESS,
      payload: [],
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCustomerLoad({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCustomerLoadService, payload.id);
    yield put({
      type: GET_LOAD_CUSTOMER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCarrierLoad({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierLoadService, payload.id, payload?.params);
    yield put({
      type: GET_LOAD_CARRIER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCarrierLastLoad({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierLastLoadService, payload.id);
    yield put({
      type: GET_CARRIER_LAST_LOAD_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getLoadById({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if (payload.loading !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getLoadByIdService, payload.id);
    yield put({
      type: GET_LOAD_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Load Transactions */

function* getLoadTransactions({ type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getLoadTransactionsService, payload.id, payload?.params);
    yield put({
      type: GET_LOAD_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
    if (payload?.success === 'success') {
      yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getLoadCarrierTransactions({ type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getLoadCarrierTransactionsService, payload.id, payload?.params);
    yield put({
      type: GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
    if (payload?.success === 'success') {
      yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getLoadOtherTransactions({ type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getLoadCarrierTransactionsService, payload.id, payload?.params);
    yield put({
      type: GET_LOAD_OTHER_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
    if (payload?.success === 'success') {
      yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Load Payable */

function* sendToPayable({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.sendToPayableService, payload.id);
    if (payload.type === 'getProcessingLoads') {
      yield put({
        type: GET_LOADS_BY_STATUS,
        payload: { params: { department: 'PROCESSING' }, load: 'noLoad' },
      });
    } else {
      yield put({
        type: GET_LOAD_BY_ID,
        payload: { loading: 'noLoad', id: payload.id },
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* approvePayable({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {

    const res = yield call(authService.appendPayableService, payload.id);
    if (payload.type === 'byId') {
      yield put({
        type: GET_LOAD_BY_ID,
        payload: { loading: 'noLoad', id: payload.id },
      });
    } else {
      yield put({
        type: GET_LOADS_BY_STATUS,
        payload: { params: { department: 'VERIFICATION' }, load: 'noLoad' },
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Pasts */

function* getPastPayable({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getPastPayableService, payload);
    yield put({
      type: GET_PAST_PAYABLE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getPastBill({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getPastBillService, payload);
    yield put({
      type: GET_PAST_BILLS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* reBill({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.reBillService, payload);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* search({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.search, payload);
    yield put({
      type: GET_SEARCH_LOAD_CUSTOMERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */


/** Receivable Notes */
function* getRecNotes({ type, payload }) {
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  if (payload?.search) {
    yield put(httpRequestsOnLoadActions.appendLoading(GET_RECEIVABLES_NOTE_SEARCH));
  }
  try {
    const res = yield call(authService.getRecNotesService, payload?.params);
    yield put({
      type: GET_RECEIVABLES_NOTE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {

  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnLoadActions.removeLoading(GET_RECEIVABLES_NOTE_SEARCH));
  }
}

function* createRecNotes({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.createRecNotesService, payload?.body);
    const res = yield call(authService.getRecNotesService, payload?.sendParams);
    yield put({
      type: GET_RECEIVABLES_NOTE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editRecNotes({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.editRecNotesService, payload?.id, payload?.body);
    const res = yield call(authService.getRecNotesService, payload?.sendParams);
    yield put({
      type: GET_RECEIVABLES_NOTE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteRecNotes({ type, payload }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.deleteRecNotesService, payload?.id);
      const res = yield call(authService.getRecNotesService, payload?.sendParams);
      yield put({
          type: GET_RECEIVABLES_NOTE_SUCCESS,
          payload: res.data,
      });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}


/** End */
export const watchLoad = function* watchLoadSaga() {
  /** Create, Edit Load */
  yield takeLatest(CREATE_LOAD, createLoad);
  yield takeLatest(CREATE_LOAD_BY_TEMPLATE, createLoadTemplate);
  yield takeLatest(EDIT_LOAD, editLoad);
  yield takeLatest(CANCEL_LOAD, cancelLoad);
  /** End */

  /** Get Loads */
  yield takeLatest(GET_LOAD_TOTALS, getLoadTotals);
  yield takeLatest(GET_LOADS_BY_STATUS, getLoadsByStatus);
  yield takeLatest(GET_LOAD_BY_ID, getLoadById);
  yield takeLatest(GET_LOAD_CUSTOMER, getCustomerLoad);
  yield takeLatest(GET_LOAD_CARRIER, getCarrierLoad);
  yield takeLatest(GET_CARRIER_LAST_LOAD, getCarrierLastLoad);
  yield takeLatest(FILTER_CARR_DEFAULT, getCarrierLoad);
  yield takeLatest(GET_LOADS_BY_PAYABLE, getLoadBbyPayable);
  yield takeLatest(GET_LOADS_RECEIVABLES, getReceivablesInfo);
  yield takeLatest(GET_LOADS_PAYABLE, getPayableInfo);
  /** End */

  /** Load Transactions */
  yield takeLatest(GET_LOAD_TRANSACTIONS, getLoadTransactions);
  yield takeLatest(GET_LOAD_CARRIER_TRANSACTIONS, getLoadCarrierTransactions);
  yield takeLatest(GET_LOAD_OTHER_TRANSACTIONS, getLoadOtherTransactions);
  /** End */

  /** Load Payable */
  yield takeLatest(SEND_TO_PAYABLE, sendToPayable);
  yield takeLatest(APPROVE_PAYABLE, approvePayable);
  /** End */

  /** Pasts */
  yield takeLatest(GET_PAST_PAYABLE, getPastPayable);
  yield takeLatest(RE_BILL, reBill);
  yield takeLatest(GET_PAST_BILLS, getPastBill);
  /** End */

  yield takeLatest(GET_SEARCH_LOAD_CUSTOMERS, search);

  /** Receivable Notes */
  yield takeLatest(GET_RECEIVABLES_NOTE, getRecNotes);
  yield takeLatest(CREATE_RECEIVABLES_NOTE, createRecNotes);
  yield takeLatest(EDIT_RECEIVABLES_NOTE, editRecNotes);
  yield takeLatest(DELETE_RECEIVABLES_NOTE, deleteRecNotes);
  /** End */

};
