import React, { useState } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { inputsStyle } from './styles';
import { useHistory } from 'react-router-dom';
import { userType } from '../../utils';
import { loadList, renderStatus } from '../../pages/loads/constants';
import { InputMinLoader } from './inputMiniLoader';

const icon = <CheckBoxOutlineBlankIcon fontSize="18" style={{ color: '#438AFE' }} />;
const checkedIcon = <CheckBoxIcon fontSize="18" style={{ color: '#438AFE' }} />;

export const CheckboxesTags = ({
                                 label,
                                 permissionsList,
                                 placeholder,
                                 handleChange,
                                 style,
                                 typeError,
                                 type,
                                 noChip,
                                 name,
                                 value,
                               }) => {
  const classes = inputsStyle();

  return (
    <Autocomplete
      className={
        noChip ? '' :
          // noChip ?
          classes.noChip
        // : ''
      }
      multiple
      value={value}
      id="checkboxes-tags-demo"
      options={permissionsList}
      disableCloseOnSelect
      onChange={(event, value) => handleChange(value)}
      getOptionLabel={(option) => type === 'mc' ? option.name : option.title}
      renderOption={(option, { selected }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <div>
            <p>{type === 'mc' ? option.name : option.title}</p>
            {name === 'permissions' &&
              <p style={{ color: 'gray', fontSize: '12px' }}>  {option?.description}</p>
            }
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={typeError}
          className={classes.inputTextFieldAutoHeight}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};


export const LoadStatus = ({ label, permissionsList, handleChange, info }) => {
  const classes = inputsStyle();

  const filtered = permissionsList?.filter((i) =>
    info?.statuses?.find((k) => i?.id === k),
  );

  return (
    <Autocomplete
      className={classes.noChipLoads}
      multiple
      autoHighlight={true}
      id="checkboxes-tags-demo"
      options={permissionsList}
      defaultValue={
        userType === 'AGENT' ? !info?.statuses ?
            [
              permissionsList[0],
              permissionsList[1],
              permissionsList[6],
              permissionsList[7],
              permissionsList[8],
            ]
            :
            filtered
          :
          filtered
      }
      disableCloseOnSelect
      onChange={(event, value) => handleChange(value)}
      getOptionLabel={(option) => option?.name}
      renderOption={(option, { selected }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <div>
            <p>{option?.name}</p>
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.inputTextFieldAutoHeight}
          variant="outlined"
          label={label}
          placeholder={
            info?.statuses?.length ?
              `${renderStatus(info?.statuses[0])} ${info?.statuses?.length > 1 ? ` +${info?.statuses?.length - 1}` : ''}`
              :
              userType === 'AGENT' && !info?.statuses ?
                'In Transit +4'
                :
                'All Loads'
          }
        />
      )}
    />
  );
};

export const MultipleSelect = ({
                                 label,
                                 permissionsList,
                                 handleChange,
                                 renderName,
                                 selectedList,
                                 smallSize,
                                 disabled,
                                 renderCheckName,error,styles, key, loading
                               }) => {
  const classes = inputsStyle();

  const filtered = permissionsList?.filter((i) =>
    selectedList?.find((k) => i?.id === k?.id),
  );

  return (
    <Autocomplete
      loading={loading}
      style={{...styles}}
      disabled={disabled}
      className={
        smallSize ? classes.noChipLoads :
          selectedList?.length ? classes.noChipTrucksBlue : classes.noChipTrucks}
      multiple
      // key={permissionsList}
      key={key ? key : selectedList}
      autoHighlight={true}
      id="checkboxes-tags-demo"
      options={permissionsList}
      defaultValue={filtered ? filtered : []}
      disableCloseOnSelect
      onChange={(event, value) => handleChange(value)}
      getOptionLabel={(option) => renderName(option)}
      renderOption={(option, { selected }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}

          />
          <div>
            <p>{renderCheckName ? renderCheckName(option) : option?.name}</p>
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          InputProps={loading ? {
            ...params.InputProps,
            endAdornment: (
              <InputMinLoader />
            ),
          } : { ...params.InputProps }
        }
          error={error}
          {...params}
          className={classes.inputTextFieldAutoHeight}
          variant="outlined"
          label={label}
          placeholder={
            selectedList?.length ?
              `${renderCheckName ? renderCheckName(selectedList[0]) : selectedList[0]?.name} ${selectedList?.length > 1 ? ` +${selectedList?.length - 1}` : ''}`
              :
              'All '
          }
        />
      )}
    />
  );
};