import { DateRow, ExportButton, PriceRow, TextRow } from 'components';
import { DownloadFile } from '../../../utils';

export const arDetailHead = [
  { name: '', title: 'Name', custom: false },
  { name: '', title: 'Load ID', custom: false },
  { name: '', title: 'Reference number', custom: false },
  { name: '', title: 'Invoice Date', custom: false },
  { name: '', title: 'Term', custom: false },
  { name: '', title: 'Due Date', custom: false },
  { name: '', title: 'Invoice Total', custom: false },
  { name: '', title: 'Paid', custom: false },
  { name: '', title: 'Paid Date', custom: false },
  { name: '', title: 'Balance', custom: false },
  { name: '', title: 'Current', custom: false },
  { name: '', title: '31-60 Days', custom: false },
  { name: '', title: '61-90 Days', custom: false },
  { name: '', title: '91+ Days', custom: false },
  { name: '', title: 'Total', custom: false },
];

export const arDetailBody = [
  { rowText: (item) => <TextRow name={item?.customerName} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.loadDisplayId} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.referenceNumber} textWidth={10} /> },
  { rowText: (item) => <DateRow date={item?.invoiceDate} /> },
  { rowText: (item) => <TextRow name={item?.term} textWidth={10} /> },
  { rowText: (item) => <DateRow date={item?.dueDate} /> },
  { rowText: (item) => <PriceRow info={item?.invoiceTotal} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.paid} textWidth={10} /> },
  { rowText: (item) => <DateRow date={item?.paidDate} /> },
  { rowText: (item) => <PriceRow info={item?.balance} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.current} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.aging31to60} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.aging61to90} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.aging90plus} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.total} textWidth={10} /> },
];

export const AR_DETAILED_REQUEST_TYPE = 'GET_AR_DETAILED';


export const arSummaryHead = [
  { name: '', title: 'Date', custom: false },
  { name: '', title: 'Action', custom: false },
];

export const arSummaryBody = (loadDownload, handleDownload) => {
  return [
    { rowText: (item) => <DateRow date={item?.createdDate}/> },
    {
      rowText: (item) => <ExportButton
        loader={item?.file?._id === loadDownload}
        handleExport={() => handleDownload({ file: item?.file, name: 'ARSummaryReportMontly.csv' })}
      />,
    },
  ];
};

export const AR_SUMMARY_REQUEST_TYPE = 'GET_AR_SUMMARY';


export const arApplicationHead = [
  { name: '', title: 'Load ID', custom: false },
  { name: '', title: 'Name', custom: false },
  { name: '', title: 'Paid', custom: false },
  { name: '', title: 'Balance', custom: false },
];

export const arApplicationBody = [
  { rowText: (item) => <TextRow name={item?.loadDisplayId} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.customerName} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.paidAmount} textWidth={10} /> },
  { rowText: (item) => <DateRow date={item?.paidDate} /> },
];

export const AR_APPLICATION_REQUEST_TYPE = 'GET_AR_APPLICATION';
