export const loadTypeList = [
  { name: 'Main', id: 'MAIN' },
  { name: 'Air', id: 'AIR' },
  { name: 'Sea', id: 'SEA' },
  { name: 'Customs', id: 'CUSTOMS' },
  { name: 'Drayage', id: 'DRAYAGE' },
  { name: 'Reefer Drayage', id: 'REEFER_DRAYAGE' },
  { name: 'Warehouse', id: 'WAREHOUSE' },
  { name: 'Cold Storage', id: 'COLD_STORAGE' },
  { name: 'Dry', id: 'DRY' },
  { name: 'Reefer', id: 'REEFER' },
  { name: 'Box Truck', id: 'BOX_TRUCK' },
  { name: 'Rail', id: 'RAIL' },
  { name: 'Rail Reefer', id: 'RAIL_REEFER' },
  { name: 'Power Only', id: 'POWER' },
  { name: 'Flatbed or Step Deck', id: 'FLATBED' },
  { name: 'Auto Carrier', id: 'AUTO_CARRIER' },
  { name: 'Sprinter Van', id: 'SPRINTER_VAN' },
  { name: 'Other', id: 'OTHER' },
];

export const loadTypeRequireStop = {
  DRY: 'DRY',
  REEFER: 'REEFER',
  BOX_TRUCK: 'BOX_TRUCK',
  POWER: 'POWER',
  FLATBED: 'FLATBED',
  AUTO_CARRIER: 'AUTO_CARRIER',
};

export const loadTypeEnums = {
  MAIN: 'MAIN',
  AIR: 'AIR',
  SEA: 'SEA',
  CUSTOMS: 'CUSTOMS',
  DRAYAGE: 'DRAYAGE',
  REEFER_DRAYAGE: 'REEFER_DRAYAGE',
  WAREHOUSE: 'WAREHOUSE',
  COLD_STORAGE: 'COLD_STORAGE',
  DRY: 'DRY',
  REEFER: 'REEFER',
  BOX_TRUCK: 'BOX_TRUCK',
  RAIL: 'RAIL',
  RAIL_REEFER: 'RAIL_REEFER',
  POWER: 'POWER',
  FLATBED: 'FLATBED',
  AUTO_CARRIER: 'AUTO_CARRIER',
  OTHER: 'OTHER',
};

export const loadStatusList = [
  { id: 'IN_TRANSIT', name: 'IN_TRANSIT' },
  { id: 'TONU', name: 'TONU' },
  { id: 'DELIVERED', name: 'DELIVERED' },
  { id: 'PAID', name: 'PAID' },
  { id: 'PENDING', name: 'PENDING' },
];

export const teamSolo = [
  { name: 'Solo', id: 'SOLO' },
  { name: 'Team', id: 'TEAM' },
];
export const bookedAs = [
  { name: 'Full', id: 'FULL' },
  { name: 'Partial', id: 'PARTIAL' },
];

// End

export const PicDropText = (modalInfo, type) => {
  if (modalInfo) {
    return type === 'pickup' ? 'Edit Pickup' :
      type === 'dropoff' ? 'Edit Dropoff' : '';
  } else {
    return type === 'pickup' ? 'Add Pickup' :
      type === 'dropoff' ? 'Add Dropoff' : '';
  }
};

export const PickDropType = (type) => {
  return type === 'pickup' ? 'PICKUP' : type === 'dropoff' ? 'DROPOFF' : '';
};