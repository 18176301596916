import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from 'store';
import { CalendarInput, FullTable, Loader } from 'components';
import { AR_SUMMARY_REQUEST_TYPE, arSummaryBody, arSummaryHead } from './constants';
import { DownloadFile, FindLoad } from 'utils';
import moment from 'moment';

export const ArSummary = ({}) => {
  const { arSummary } = useSelector((state) => ({
    arSummary: state.reports.arSummary,
  }));
  const dispatch = useDispatch();
  const loader = FindLoad(AR_SUMMARY_REQUEST_TYPE);
  const { loadDownload, handleDownload } = DownloadFile();
  const [date, selectedDate] = useState(null);

  useEffect(() => {
    handleGetDate();
  }, []);

  const handleGetDate = (sendInfo) => {
    dispatch(reportActions.getArSummaryReport(sendInfo));
  };

  const handleChangeDate = (e) => {
    selectedDate(e.target.value);
    const date = moment(e.target.value)
    const sendInfo = {};
    if (e.target.value) {
      sendInfo.month = date.month();
      sendInfo.year = date.year();
    } else {
      delete sendInfo.month;
      delete sendInfo.year;
    }
    handleGetDate(sendInfo);
  };

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex-end">
        <CalendarInput
          value={date}
          handleChangeDate={handleChangeDate}
        />
      </div>
      <FullTable
        head={arSummaryHead}
        body={arSummaryBody(loadDownload, handleDownload)}
        list={arSummary?.reports || []}
        noText={'AR Summary'}
      />
    </div>
  );
};