import axios from "axios";

export const authService = {
    /** Create, Edit Load */

    createLoadService: (body) => axios.post('/loads', body, {auth: true}),

    createLoadTemplateService: (id) => axios.post(`/loads/fromTemplate/${id}`, null, {auth: true}),

    editLoadService: (body, id) => axios.patch(`/loads/${id}`, body, {auth: true}),

    cancelLoadService: (id) => axios.post(`/loads/${id}/cancel`, {}, {auth: true}),

    /** End */

    /** Get Loads */

    getLoadsByPayableService: (id) => axios.get(`/loads/payables/${id}`, {auth: true}),

    getLoadTotalsService: () => axios.get('/loads/totals', {auth: true}),

    getLoadsByStatusService: (params) => axios.get('/loads', {auth: true, params: {...params}}),

    getCustomerLoadService: (id) => axios.get(`/loads/customer/${id}`, {auth: true}),

    getCarrierLoadService: (id) => axios.get(`/loads/carrier/${id}`, {auth: true}),

    getCarrierLastLoadService: (id) => axios.get(`/loads/carrier/${id}/lastLoads`, {auth: true}),

    getLoadByIdService: (id) => axios.get(`/loads/${id}`, {auth: true}),

    getReceivablesInfoService: (params) => axios.get(`/loads/receivables/accounting/totals`, {auth: true, params: {...params}}),

    getPayableInfoService: (params) => axios.get(`/loads/payable/accounting/totals`, {auth: true, params: {...params}}),

    /** End */


    /** Load Transactions */

    getLoadTransactionsService: (id, params) => axios.get(`/loads/${id}/txns`, {auth: true, params: {...params}}),

    getLoadCarrierTransactionsService: (id, params) => axios.get(`/loads/${id}/txns`, {auth: true, params: {...params}}),

    createLoadTransactionsService: (id, date) => axios.post(`/loads/${id}/txns`, date, {auth: true}),

    editLoadTransactionsService: (id, txnsId) => axios.patch(`/loads/${id}/txns/${txnsId}/void`, null, {auth: true}),

    /** End */

    /** Load Payable */

    sendToPayableService: (id) => axios.post(`/loads/${id}/payables/send`, null, {auth: true}),

    appendPayableService: (id) => axios.patch(`/loads/${id}/payables/approve`, null, {auth: true}),

    /** End */

    /** Pasts */

    getPastPayableService: (id) => axios.get(`/loads/${id}/pastPayables`, {auth: true}),

    getPastBillService: (id) => axios.get(`/loads/${id}/pastBills`, {auth: true}),

    reBillService: (id) => axios.post(`/loads/${id}/billing/rebill`, null, {auth: true}),

    /** End */

    search: (name) => axios.get(`/customers/search`, {auth: true, params: {searchString: name, status:'ACTIVE'}}),

    getLoadBYCustomers: (ids) => axios.get(`/loads/notes/customers`, {auth: true, params: {ids: ids}}),

    /** Receivable Notes */

    getRecNotesService: (params) => axios.get(`/receivable-notes`, {auth: true, params: {...params}}),

    createRecNotesService: (body) => axios.post(`/receivable-notes`, body,{auth: true}),

    editRecNotesService: (id, body) => axios.patch(`/receivable-notes/${id}`, body,{auth: true}),

    deleteRecNotesService: (id, body) => axios.delete(`/receivable-notes/${id}`,{auth: true}),

    /** End */
};
