import React from 'react';
import { useParams } from 'react-router-dom';
import { Images, useSecondaryModal } from 'utils';
import { MoteDrawerModal } from './noteDrawerModal';
import { HtmlTooltip } from '../messages';

export const NoteDrawer = ({ title }) => {
  const params = useParams();
  const { openSecondary } = useSecondaryModal({ noPadding: true });

  return (
    <div>
      <HtmlTooltip
        title={<p>Manage Notes</p>}
        placement="top-start"
      >
        <button className={'reset-btn'} onClick={() => openSecondary(
          <MoteDrawerModal
            title={title}
            params={params}
          />)
        }>
          <img src={Images.manageNotes} alt={'manageNotes'} />
        </button>
      </HtmlTooltip>
    </div>
  );
};