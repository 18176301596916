import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from 'react-rte';
import moment from 'moment/moment';
import button from 'react-rte/lib/ui/Button';
import { noteStyles } from './styles';
import { CloseButton, CreateChancel, Loader, MinLoader, MultipleCheckBox, TextRow } from 'components';
import { FindLoad, FindSuccessItem, Images, useModal, isAdmin, useSecondaryModal } from 'utils';
import { customerActions, httpRequestsOnLoadActions, httpRequestsOnSuccessActions, loadActions } from 'store';
import { CustomDeleteModal } from 'fragments';
import { authService } from '../../../../store/loads/loads.service';
import RichTextEditorExample from './noteTextEditor';

const DELETE_ACTION_TYPE = 'DELETE_RECEIVABLES_NOTE';
const GET_ACTION_TYPE = 'GET_RECEIVABLES_NOTE';
const GET_LOAD_BY_CUSTOMERS = 'GET_LOAD_BY_CUSTOMERS';
export const ReceivableNoteDrawerModal = ({}) => {
  const { customersList, receivableNotes } = useSelector((state) => ({
    customersList: state.customers.customersList,
    receivableNotes: state.loads.receivableNotes,
  }));
  const classes = noteStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [inputs, setInputs] = useState({});
  const [selectedNote, setSelectedNote] = useState('');
  const inputRef = useRef();
  const ACTION_TYPE = inputs?.id ? 'EDIT_RECEIVABLES_NOTE' : 'CREATE_RECEIVABLES_NOTE';
  const success = FindSuccessItem(ACTION_TYPE);
  const loader = FindLoad(ACTION_TYPE);
  const loadList = FindLoad(GET_ACTION_TYPE);
  const { open, close } = useModal();
  const { closeSecondary } = useSecondaryModal();
  const [customerLoads, setCustomerLoads] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const searchLoad = FindLoad('GET_RECEIVABLES_NOTE_SEARCH');
  const loadLoads = FindLoad(GET_LOAD_BY_CUSTOMERS);

  useEffect(() => {
    getNotes();
  }, [dispatch]);

  const getNotes = (loading) => {
    dispatch(loadActions.getRecNotes({}, loading));
  };

  const handleSearch = () => {
    setSelectedNote('');
    const params = {};
    searchValue ? params.searchKey = searchValue : delete params.searchKey;
    dispatch(loadActions.getRecNotes(params, 'noLoad', true));
  };

  useEffect(() => {
    setCustomerLoads([]);
    dispatch(customerActions.getCustomers({ status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    if (success?.type === ACTION_TYPE && addNote) {
      setAddNote(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
    if (success?.type === ACTION_TYPE && selectedNote) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
  }, [success]);

  const handleSubmit = () => {
    if (inputs?.loads?.length && inputs?.customers?.length && inputs?.note) {

      const loads = inputs?.loads?.map((item) => item.id);
      const customers = inputs?.customers?.map((item) => item.id);

      const noteData = {
        title: inputs?.title ? inputs?.title : ' ',
        note: inputs?.note?.toString('html'),
        loads: loads,
        customers: customers,
      };

      if (inputs?.id) {
        const editParams = {
          ...inputs,
        };
        editParams.note = inputs?.note?.toString('html');
        setSelectedNote(editParams);

        const sendParams = {};
        searchValue ? sendParams.searchKey = searchValue : delete sendParams.searchKey;
        dispatch(loadActions.editRecNote(inputs?.id, noteData, sendParams));
      } else {
        dispatch(loadActions.createRecNote(noteData));
      }
    } else {
      setError(
        !inputs?.customers?.length ? 'customers' :
          !inputs?.loads?.length ? 'loads' :
            !inputs?.note ? 'note' : '',
      );
    }
  };

  const handleChange = (e) => {
    setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleCancel = () => {
    setError('');
    setInputs({});
    setAddNote(false);
  };

  const handleAddNote = () => {
    getNotes('noLoad');
    setAddNote(true);
    setEditNote(false);
    setInputs({});
    setSelectedNote('');
    setSearchValue('');
    setError('');
  };

  const handleEditNote = () => {
    setError('');
    setEditNote(true);
    setAddNote(true);
    const params = {
      ...selectedNote,
    };
    params.note = RichTextEditor.createValueFromString(selectedNote?.note, 'html');
    setInputs(params);
  };

  const renderNoteBtn = () => {
    return (
      <button className={classes.addBtn} onClick={handleAddNote}>
        <div>
          <img src={Images.addWhiteIcon} alt={'icon'} />
        </div>
        <p>{'Add Note'}</p>
      </button>
    );
  };

  const handleSelect = (value, name) => {
    setInputs(prevState => ({ ...prevState, [name]: value }));

    if (name === 'customers') {
      const newParams = {
        ...inputs,
      };

      if (value?.length) {
        const ids = value.map((item) => item.id);
        getCustomerLoad(ids);
        newParams.customers = value;
        delete newParams.loads;
        setInputs(newParams);
      } else {
        setCustomerLoads([]);
        delete newParams.loads;
        delete newParams.customers;
        setInputs(newParams);
      }
    }
    if (error === name) {
      setError('');
    }
  };


  // const newLoadList =
  //   res?.data?.filter(function (array_el) {
  //     return inputs?.loads?.filter(function (anotherOne_el) {
  //       return array_el.id === anotherOne_el.id;
  //     }).length === 0
  //   });
  //
  // if(inputs?.loads?.length) {
  //   setInputs(prevState => ({ ...prevState, ['loads']: newLoadList }));
  // }
  //
  // console.log(newLoadList,'newLoadList');


  const getCustomerLoad = (ids) => {
    dispatch(httpRequestsOnLoadActions.appendLoading(GET_LOAD_BY_CUSTOMERS));
    authService.getLoadBYCustomers(ids).then((res) => {
      setCustomerLoads(res?.data);
    }).catch(() => {
      setCustomerLoads([]);
    }).finally(() => {
      dispatch(httpRequestsOnLoadActions.removeLoading(GET_LOAD_BY_CUSTOMERS));
    });
  };

  const noteLeftSide = () => {
    if (addNote) {
      return (
        <div className={classes.noteLetSide}>
          {/*<input*/}
          {/*  ref={inputRef}*/}
          {/*  onChange={handleChange}*/}
          {/*  value={inputs?.title ? inputs?.title : ''}*/}
          {/*  name={'title'}*/}
          {/*  type="text"*/}
          {/*  placeholder={'Untitled'}*/}
          {/*  className="note-title-input"*/}
          {/*  maxLength={50}*/}
          {/*/>*/}
          <div className="note-subject-box">
            <div className="align-center" style={{ gap: 16 }}>
              <MultipleCheckBox
                handleChange={(e) => handleSelect(e, 'customers')}
                name="customers"
                renderValue={(item) => item?.name}
                value={inputs?.customers ? inputs?.customers : []}
                permissionsList={customersList?.customers || []}
                label={'Choose Customer*'}
                placeholder={inputs?.customers?.length ? inputs?.customers?.map((i) => i?.name).join(', ') : 'All'}
                uiType={'tableFilter'}
                typeError={error === 'customers'}
              />
              <MultipleCheckBox
                loadType={GET_LOAD_BY_CUSTOMERS}
                handleChange={(e) => handleSelect(e, 'loads')}
                name="loads"
                renderValue={(item) => item?.displayId}
                value={inputs?.loads ? inputs?.loads : []}
                permissionsList={customerLoads || []}
                label={'Load ID*'}
                placeholder={inputs?.loads?.length ? inputs?.loads?.map((i) => i?.displayId).join(', ') : 'All'}
                uiType={'tableFilter'}
                disabled={loadLoads?.length}
                typeError={error === 'loads'}
              />

              {/*<MultipleSelect*/}
              {/*  selectedList={inputs?.customers ? inputs?.customers : []}*/}
              {/*  handleChange={(e) => handleSelect(e, 'customers')}*/}
              {/*  permissionsList={customersList?.customers || []}*/}
              {/*  label={'Choose Customer*'}*/}
              {/*  renderName={(item) => item?.name}*/}
              {/*  smallSize={true}*/}
              {/*  error={error === 'customers'}*/}
              {/*  styles={{ width: '100%' }}*/}
              {/*  key={customersList?.customers}*/}
              {/*/>*/}
              {/*<MultipleSelect*/}
              {/*  loading={loadLoads}*/}
              {/*  key={loadLoads}*/}
              {/*  selectedList={inputs?.loads ? inputs?.loads : []}*/}
              {/*  handleChange={(e) => handleSelect(e, 'loads')}*/}
              {/*  permissionsList={customerLoads || []}*/}
              {/*  label={'Load ID*'}*/}
              {/*  renderName={(item) => item?.displayId}*/}
              {/*  renderCheckName={(item) => item?.displayId}*/}
              {/*  smallSize={true}*/}
              {/*  disabled={!inputs?.customers?.length}*/}
              {/*  error={error === 'loads'}*/}
              {/*  styles={{ width: '100%' }}*/}
              {/*/>*/}
            </div>
            <div style={{marginTop: '-20px'}}>
              <RichTextEditorExample
                setInputs={setInputs}
                inputs={inputs}
                error={error === 'note'}
                setError={setError}
              />
            </div>
          </div>
          <div className="flex-end">
            <CreateChancel
              loader={!!loader.length}
              create={'Save'}
              chancel={'Cancel'}
              onCreate={handleSubmit}
              onClose={handleCancel}
              width="93px"
            />
          </div>
        </div>
      );
    }

    if (receivableNotes?.length && !selectedNote && !addNote) {
      return (
        <div className={classes.noteLetSide}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle} style={{ margin: '16px 0 8px' }}>Select a note to
            view</p>
          <p className={classes.noteInformationSubject}>Choose a note from the list on the left to view its
            contents, <br /> or create a new note to add to your collection.</p>
        </div>
      );
    }

    if (!receivableNotes?.length && !addNote) {
      return (
        <div className={classes.noteLetSide} style={{ background: 'transparent' }}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle}>{`No notes available for this Receivable.`}</p>
          {renderNoteBtn()}
        </div>
      );
    }

    if (selectedNote) {
      return (
        <div className={classes.noteLetSide}
             style={{ justifyContent: 'flex-start', alignItems: 'flex-start', padding: '2px 16px 16px 4px' }}>
          <div className="space-between" style={{ alignItems: 'flex-start' }}>
            <p className={classes.selectedNoteTitle}>{selectedNote?.title ? selectedNote?.title : ''}</p>
            <div className="align-center" style={{ gap: 12 }}>
              <button
                className="reset-btn"
                onClick={() => open(
                  <CustomDeleteModal
                    afterSuccess={() => {
                      setSelectedNote('');
                      close();
                    }}
                    actionType={DELETE_ACTION_TYPE}
                    text="Delete note?"
                    info={'Note'}
                    handleDel={() => dispatch(loadActions.deleteRecNote(selectedNote?.id))}
                  />,
                )}
              >
                <img src={Images.removeRed} alt="delete" />
              </button>
              {isAdmin &&
                <button className="reset-btn" onClick={handleEditNote}>
                  <img src={Images.edit} alt="edit" />
                </button>
              }
            </div>
          </div>

          <div className="note-customer-load-wrapper">
            <div className="note-customer-load-box">
              <p>Customers:</p>
              {selectedNote?.customers?.map((item, index) => (
                <div style={{ background: 'rgba(67, 138, 254, 0.15)' }} key={index}>{item?.name}</div>
              ))}
            </div>
            <div className="note-customer-load-box">
              <p>Loads:</p>
              {selectedNote?.loads?.map((item, index) => (
                <div style={{ background: 'rgba(0, 200, 81, 0.10)' }} key={index}>{item?.displayId}</div>
              ))}
            </div>
          </div>
          <div className={classes.noteInformationBox}>

          </div>
          <p className={classes.noteSubjectText}
             dangerouslySetInnerHTML={{ __html: selectedNote?.note }}
          >
          </p>
        </div>
      );
    }

    if (!receivableNotes?.length && !addNote) {
      return (
        <div className={classes.noteLetSide}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle}>{`No notes available for this Receivable.`}</p>
          {renderNoteBtn()}
          {/*<AddCircle handleCLic={handleAddNote} text={'Add Note'} />*/}
        </div>
      );
    }
  };

  const handleSelectNote = (item) => {
    getCustomerLoad(item?.customers?.map((item) => item.id));
    setSelectedNote(item);
    setAddNote(false);
  };

  // if (loadList?.length) {
  //   return <Loader height={'100vh'} />;
  // }

  return (
    <div className="note-modal-wrapper">
      {loadList?.length ?
        <Loader height={'100%'} />
        :
        <div>
          <div className={classes.flexEnd}>
            <CloseButton
              handleCLic={closeSecondary}
              styles={{ marginRight: '10px' }}
            />
          </div>

          <div className="receivables-note-header">
            <p className={classes.noteModalTitle}>Receivables Notes</p>
            {!!receivableNotes?.length &&
              renderNoteBtn()
            }
          </div>

          <div className={classes.noteModalBox}>
            <div className={classes.nodeModalBody}>
              <div className={classes.userNotes}>
                <div className="search-note-box">
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'by customer name, load ID, reference...'}
                    type="text"
                    value={searchValue}
                  />
                  <button onClick={handleSearch}>
                    {searchLoad?.length ?
                      <MinLoader margin={'0'} color={'#51566D'} position={'relative'} />
                      :
                      <img src={Images.searchIcon} alt="icon" />
                    }
                  </button>
                </div>


                {addNote && !editNote &&
                  <div
                    style={{ background: '#F8FAFB' }}
                    className={classes.noteBox}
                  >
                    {/*<p className={classes.noteTitle}>*/}
                    {/*  {inputs?.title}*/}
                    {/*</p>*/}
                    <p className={classes.noteInfo}>
                      {moment.utc().format('LT')}
                    </p>
                  </div>
                }

                {!!receivableNotes?.length &&
                  <div className={classes.reverse}>
                    {receivableNotes?.map((i, j) => (
                      <div
                        style={
                          selectedNote?.id === i?.id ?
                            {
                              background: '#F7F9FC',
                              border: '1px solid #438AFE',
                            }
                            :
                            {
                              background: 'transparent',
                              border: '1px solid #E6ECF3',
                            }
                        }
                        className={classes.noteBox} key={j} onClick={() => handleSelectNote(i)}
                      >
                        {/*<p style={{ color: selectedNote?.id === i?.id ? '#438AFE' : '#172B4D' }} className={classes.noteTitle}>{i?.title}</p>*/}
                        <p className={classes.noteSubject} dangerouslySetInnerHTML={{ __html: i?.note }}>
                          {/*{i?.note}*/}
                        </p>

                        <div className={classes.noteInfo}
                             style={{ borderTop: '1px solid #E6ECF3', paddingTop: 8 }}
                        >
                          <div className="flex" style={{ gap: 8 }}>
                            <p>
                              {moment.utc(i?.createdDate).format('MM/DD/YYYY')}
                            </p>
                            <p>
                              {moment(i?.createdDate).format('LT')}
                            </p>
                          </div>

                          <div style={{ width: '180px' }}>
                            {i?.user?.firstName &&
                              <TextRow name={`by ${i?.user?.firstName} ${i?.user?.lastName}`} textWidth={7} />
                            }
                          </div>

                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
              {/*}*/}

              <div style={{ width: '100%' }}>
                {noteLeftSide()}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};