import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Circle, DocumentsComponent } from 'components';
import { Colors, useGlobalStyles, useGlobalText, isAdmin } from 'utils';
import { CreateOfficeInputs } from '../createOffice';
import { OfficeManagement } from './core/officeManagement';
import { PayrollSetup } from './core/payrollSetup';
import { officeDockTypes } from './core/constant';
import { Expenses } from './core/expenses';
import { authService } from '../../../store/mc/mc.service';
import { httpRequestsOnLoadActions, httpRequestsOnSuccessActions, officeActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';

const ADD_OFFICE_DOCK = 'ADD_OFFICE_DOCK';
const DELETE_OFFICE_DOCK = 'DELETE_OFFICE_DOCK';

export const OfficeInfoTable = ({ handleChangeName }) => {
  const { officeById } = useSelector((state) => ({
      officeById: state.offices.officeById,
    }),
  );
  const globalStyle = useGlobalText();
  const globalInputs = useGlobalStyles();
  const [docs, setDocks] = useState([]);
  const dispatch = useDispatch();
  const info = officeById;

  useEffect(() => {
    if (info && info?.documents) {
      setDocks(info.documents);
    }
  }, [info]);

  const handleAddDocument = async (img) => {
    try {
      await authService.addOfficeDocument(img, info?.id);
      dispatch(httpRequestsOnLoadActions.removeLoading(ADD_OFFICE_DOCK));
      dispatch(officeActions.getOfficeById(info?.id, 'noLoad'));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(ADD_OFFICE_DOCK));
    }
  };

  const handleDeleteFile = async (doc) => {
    try {
      dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_OFFICE_DOCK));
      await authService.deleteOfficeDocument(info.id, doc.id);
      dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_OFFICE_DOCK));
      dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_OFFICE_DOCK));
      dispatch(officeActions.getOfficeById(info.id, 'noLoad'));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_OFFICE_DOCK));
    }
  };

  const handleFilter = e => {
    if (e.target.value === 'All DocumentsSection') {
      setDocks(info.documents);
    } else {
      const newList = info && info.documents.filter((i) => i.type === e.target.value);
      setDocks(newList);
    }
  };

  return (
    <div className="accordion-style">
      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={1} back={Colors.ThemeOrange} />}
            <p className={globalStyle.title}>Office Information</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <CreateOfficeInputs
            handleChangeName={handleChangeName}
            info={info}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={2} back={Colors.ThemeOrange} />}
            <p className={globalStyle.title}>Office Expenses</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Expenses />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={3} back={Colors.ThemeOrange} />}
            <p className={globalStyle.title}>Office Management</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <OfficeManagement />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={4} back={Colors.ThemeOrange} />}
            <p className={globalStyle.title}>Payroll Setup</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: 0, width: '100%' }}>
            <PayrollSetup info={info} />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={5} back={Colors.ThemeOrange} />}
            <p className={globalStyle.title}>Documents</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="info-table-no-line">
            <DocumentsComponent
              fileTypeList={officeDockTypes}
              docTypes={[
                  { name: 'All Documents', id: 'All DocumentsSection' },
                  ...officeDockTypes,
              ]}
              actionType={ADD_OFFICE_DOCK}
              removeActionType={DELETE_OFFICE_DOCK}
              handleDeleteFile={handleDeleteFile}
              handleUpload={handleAddDocument}
              handleFilter={handleFilter}
              documents={docs}
              name={'typeName'}
              noUpload={!isAdmin}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};