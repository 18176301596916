import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AuthorityInfo} from "fragments";
import {httpRequestsOnSuccessActions, mcActions, officeActions} from "store";
import {
    ActiveInactive,
    History,
    Loader, NoteDrawer, Notes,
    SimpleModal,
    SimpleTabs, SlicedText,
} from 'components';
import {
    AntSwitch,
    FindLoad,
    FindSuccessItem,
    Images,
    PermissionsList,
    RenderStatus,
    RolePermission,
    useGlobalStyles,
} from "utils";

export const AuthoritiesInfoPage = () => {
    const {mcById} = useSelector((state) => ({
            mcById: state.mcs.mcById,
        })
    )
    const dispatch = useDispatch()
    const params = useParams()
    const globalStyle = useGlobalStyles()
    const [name, setName] = useState('')
    const loader = FindLoad('GET_MC_BY_ID')
    const [switchBoolean, setSwitchBoolean] = useState(false)
    const [open, setOpen] = useState(false)
    const authorityName = name ? name : mcById && mcById.name
    const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate'
    const activeSuccess = FindSuccessItem('ACTIVATE_MC')
    const inactiveSuccess = FindSuccessItem('INACTIVATE_MC')
    const activeLoad = FindLoad('ACTIVATE_MC')
    const inactiveLoad = FindLoad('INACTIVATE_MC')

    useEffect(() => {
        if (mcById) {
            setSwitchBoolean(mcById.status === 'ACTIVE')
        }
    }, [mcById])

    useEffect(() => {
        dispatch(mcActions.getMcById(params.id))
        dispatch(officeActions.getOffices({status: 'ACTIVE'}))
        dispatch(mcActions.getAssignedMs(params.id, 'OFFICE'))
    }, [])

    useEffect(() => {
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_MC'))
            setSwitchBoolean(true)
            setOpen(false)
        }
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('inactiveSuccess'))
            setSwitchBoolean(false)
            setOpen(false)
        }
    }, [activeSuccess])

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSwitch = () => {
        if (switchBoolean) {
            dispatch(mcActions.inactivateMc(mcById.id))
        } else {
            dispatch(mcActions.activateMc(mcById.id))
        }
    }

    const tabsLabels = [
        {
            label: 'Details'
        },
        {
            label: 'History'
        },
        // {
        //     label: 'Notes'
        // },
    ]

    const tabsContent = [
        {
            tabComponent: <AuthorityInfo setName={setName} />,
            tab: 'Details'
        },
        {
            tabComponent: <History onModel={'mc'} resourceId={params.id}/>,
            tab: 'History'
        },
        // {
        //     tabComponent:  <Notes/>,
        //     tab: 'Notes'
        // }
    ];

    if (loader.length) return <Loader/>

    return (
        <>
            <SimpleTabs
                head={
                    <div className="space-between" style={{ gap: 15 }}>
                        <div className={globalStyle.centerItem}>
                            {RenderStatus(activeInactive)}
                            {RolePermission([PermissionsList.MC_STATUS?.code]) &&
                              <AntSwitch
                                onClick={handleOpenClose}
                                checked={switchBoolean}
                              />
                            }
                        </div>
                        <NoteDrawer
                          title={'Authority'}
                        />
                        <div className={globalStyle.centerItem}>
                            <img style={{ marginRight: '4px' }} src={Images.authorityBlueFill}
                                 alt={'authorityBlueFill'} />
                            <SlicedText type={'tableName'} size={20} data={authorityName && authorityName} />
                        </div>
                    </div>
                }
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                parentLink={'/authorities'}
                parent={'Authorities'}
                child={'Authority Info'}
                defaultTab={'Details'}
                paramsName={'authorityParams'}
            />

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ActiveInactive
                        loading={!!activeLoad?.length || !!inactiveLoad?.length}
                        text={switchBoolean === true ? `Inactivate ${authorityName}` : `Activate ${authorityName}`}
                        textInfo={activeInactive}
                        name={authorityName}
                        handleClose={handleOpenClose}
                        handleDel={handleSwitch}
                    />
                }
            />
        </>
    )
}