import React, { useState, Fragment, useContext, createContext } from 'react';
import { CloseButton, SimpleModal } from 'components';


export const ModalContext = createContext();


export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState('');
  const [params, setParams] = useState({});
  const [modalInfo, setModalInfo] = useState({});

  return (
    <Fragment>
      <ModalContext.Provider
        value={{
          activeModal,
          setActiveModal,
          params,
          setParams,
          setModalInfo,
          modalInfo,
        }}>

        {children}
      </ModalContext.Provider>
    </Fragment>
  );
};

export const useModal = (info) => {
  const { setActiveModal, params, setParams, setModalInfo, modalInfo } = useContext(ModalContext);

  return {
    open: (params = {}) => {
      setActiveModal(true);
      setParams(params);
      setModalInfo(info)
    },
    close: () => {
      setActiveModal('');
      setParams({});
      setModalInfo({});
    },
    params,
    modalInfo
  };
};


export const AllModals = () => {
  const { activeModal } = useContext(ModalContext);
  const { close, params, modalInfo } = useModal();


  return (
    <SimpleModal
      handleOpenClose={() => close('')}
      openDefault={activeModal ? activeModal : false}
      content={
        <div style={{ outline: 'none' }} className="modal-wrapper-style">
          <div className="modal-header-style">
            <CloseButton handleCLic={close} />
          </div>
          <div className={modalInfo?.noPadding ? 'modal-body-style-no-padding' : "modal-body-style"}>
            {params}
          </div>
        </div>
      }
    />
  );
};