import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import button from 'react-rte/lib/ui/Button';
import { noteStyles } from './styles';
import { CloseButton, CreateChancel, Loader, TextRow } from 'components';
import { FindLoad, FindSuccessItem, Images, useModal, isAdmin, useSecondaryModal } from 'utils';
import { httpRequestsOnSuccessActions, noteActions } from 'store';
import { CustomDeleteModal } from '../../fragments';

export const MoteDrawerModal = ({ params, title }) => {
  const notes = useSelector((state) => state.note.notes);
  const classes = noteStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [inputs, setInputs] = useState({});
  const [selectedNote, setSelectedNote] = useState('');
  const inputRef = useRef();
  const textAreaRef = useRef();
  const ACTION_TYPE = inputs?.id ? 'EDIT_GLOBAL_NOTE' : 'CREATE_GLOBAL_NOTE';
  const success = FindSuccessItem(ACTION_TYPE);
  const loader = FindLoad(ACTION_TYPE);
  const loadList = FindLoad('GET_GLOBAL_NOTES');
  const { open, close } = useModal();
  const { closeSecondary } = useSecondaryModal();
  const currentId = params?.id;

  useEffect(() => {
    getNotes();
  }, [dispatch]);

  const getNotes = (loading) => {
    const pushInfo = {};
    pushInfo.skip = 0;
    pushInfo.limit = 1000;
    const load = loading === 'noLoad' ? 'noLoad' : 'load';
    dispatch(noteActions.getGlobalNotes(params?.id, { ...pushInfo }, load));
  };


  useEffect(() => {
    if (success?.type === ACTION_TYPE && addNote) {
      setAddNote(false);
      getNotes('noLoad');
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
    if (success?.type === ACTION_TYPE && selectedNote) {
      // setSelectedNote(current);
      getNotes('noLoad');
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
  }, [success]);

  const handleSubmit = () => {
    if (inputs?.text) {
      const noteData = {
        subject: inputs?.subject ? inputs?.subject : ' ',
        text: inputs?.text,
        resource: currentId,
      };
      if (inputs?.id) {
        const editParams = {
          ...selectedNote,
        };
        // editParams.subject = inputs?.subject;
        editParams.text = inputs?.text;
        setSelectedNote(editParams);

        dispatch(noteActions.editGlobalNote(currentId, inputs?.id, noteData));
      } else {
        dispatch(noteActions.createGlobalNote(noteData));
      }
    } else {
      // if (!inputs?.subject) {
      //   inputRef.current.focus();
      // }
      // if (inputs?.subject && !inputs?.text) {
      //   textAreaRef.current.focus();
      // }
      if (!inputs?.text) {
        textAreaRef.current.focus();
      }
    }
  };

  const handleChange = (e) => {
    setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleCancel = () => {
    setInputs({});
    setAddNote(false);
  };

  const handleAddNote = () => {
    setAddNote(true);
    setEditNote(false);
    setInputs({});
    setSelectedNote('');
  };

  const handleEditNote = () => {
    setEditNote(true);
    setAddNote(true);
    setInputs({
      ...selectedNote,
    });
  };

  const renderNoteBtn = () => {
    return (
      <button className={classes.addBtn} onClick={handleAddNote}>
        <div>
          <img src={Images.addWhiteIcon} alt={'icon'} />
        </div>
        <p>{'Add Note'}</p>
      </button>
    );
  };
  const noteLeftSide = () => {
    if (addNote) {
      return (
        <div className={classes.noteLetSide}>
          {/*<input*/}
          {/*  ref={inputRef}*/}
          {/*  onChange={handleChange}*/}
          {/*  value={inputs?.subject ? inputs?.subject : ''}*/}
          {/*  name={'subject'}*/}
          {/*  type="text"*/}
          {/*  placeholder={'Untitled'}*/}
          {/*  className="note-title-input"*/}
          {/*  maxLength={50}*/}
          {/*/>*/}

          <div className={classes.noteSubjectBox}>
            {/*<div className={classes.characters}>*/}
            {/*  <p>{`${inputs?.subject?.length ? inputs?.subject?.length : 0}/50 characters`}</p>*/}
            {/*</div>*/}
            <textarea
              ref={textAreaRef}
              onChange={handleChange}
              value={inputs?.text ? inputs?.text : ''}
              name={'text'}
              placeholder={'Provide details for your note...'}
              className={classes.noteSubjectArea}
              maxLength={400}
            />
          </div>
          <div className="flex-end">
            <CreateChancel
              loader={!!loader.length}
              create={'Save'}
              chancel={'Cancel'}
              onCreate={handleSubmit}
              onClose={handleCancel}
              width="93px"
            />
          </div>
        </div>
      );
    }

    if (notes?.length && !selectedNote && !addNote) {
      return (
        <div className={classes.noteLetSide}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle} style={{ margin: '16px 0 8px' }}>Select a note to
            view</p>
          <p className={classes.noteInformationSubject}>Choose a note from the list on the left to view its
            contents, <br /> or create a new note to add to your collection.</p>
        </div>
      );
    }

    if (!notes?.length && !addNote) {
      return (
        <div className={classes.noteLetSide} style={{ background: 'transparent' }}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle}>{`No notes available for this ${title}.`}</p>
          {renderNoteBtn()}
        </div>
      );
    }

    if (selectedNote) {
      return (
        <div className={classes.noteLetSide} style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <div className="space-between" style={{ alignItems: 'flex-start' }}>
            <p className={classes.selectedNoteTitle}>{selectedNote?.subject ? selectedNote?.subject : ' '}</p>
            <div className="align-center" style={{ gap: 12 }}>
              <button
                className="reset-btn"
                onClick={() => open(
                  <CustomDeleteModal
                    afterSuccess={() => {
                      getNotes('noLoad');
                      setSelectedNote('');
                      close();
                    }}
                    actionType={'DELETE_GLOBAL_NOTE'}
                    text="Delete note?"
                    info={'Note'}
                    handleDel={() => dispatch(noteActions.deleteGlobalNote(selectedNote?.id, currentId))}
                  />,
                )}
              >
                <img src={Images.removeRed} alt="delete" />
              </button>
              {isAdmin &&
                <button className="reset-btn" onClick={handleEditNote}>
                  <img src={Images.edit} alt="edit" />
                </button>
              }
            </div>
          </div>
          <div className={classes.noteInformationBox}>
            {/*  {selectedNote?.user?.firstName &&*/}
            {/*    <div className={classes.noteInformation}>*/}
            {/*      <img src={Images.noteCreator} alt="icon" />*/}
            {/*      <p>Created By: <span>{`${selectedNote?.user?.firstName} ${selectedNote?.user?.lastName}`}</span>*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*  <div className={classes.noteInformation}>*/}
            {/*    <img src={Images.noteCreatedDate} alt="icon" />*/}
            {/*    <p>*/}
            {/*      Created Date: <span>{moment.utc(selectedNote?.createdDate).format('MM/DD/YYYY')}</span>*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*  <div className={classes.noteInformation}>*/}
            {/*    <img src={Images.noteCreatedTime} alt="icon" />*/}
            {/*    <p>Created Time: <span>{moment.utc(selectedNote?.createdDate).format('LT')}</span></p>*/}
            {/*  </div>*/}
          </div>
          <p className={classes.noteSubjectText}>
            {selectedNote?.text}
          </p>
        </div>
      );
    }

    if (!notes?.length && !addNote) {
      return (
        <div className={classes.noteLetSide}>
          <img className={classes.noteImage} src={Images.notes} alt="icon" />
          <p className={classes.noteInformationTitle}>{`No notes available for this ${title}.`}</p>
          {renderNoteBtn()}
          {/*<AddCircle handleCLic={handleAddNote} text={'Add Note'} />*/}
        </div>
      );
    }
  };

  const handleSelectNote = (item) => {
    setSelectedNote(item);
    setAddNote(false);
  };

  // if (loadList?.length) {
  //   return <Loader height={'100vh'} />;
  // }

  return (
    <div
      className={classes.noteModal}
      style={{ fontFamily: 'Open Sans, sans-serif', wordWrap: 'break-word' }}>
      {loadList?.length ?
        <Loader height={'100%'} />
        :
        <div>
          <div className={classes.flexEnd}>
            <CloseButton
              handleCLic={closeSecondary}
              styles={{ marginRight: '10px' }}
            />
          </div>
          <div className={classes.noteModalBox}>
            <div className="space-between">
              <p className={classes.noteModalTitle}>{`${title} Notes`}</p>
              {!!notes?.length &&
                renderNoteBtn()
              }
            </div>
            <div className={classes.nodeModalBody}>
              {!!notes?.length &&
                <div className={classes.userNotes}>
                  {addNote && !editNote &&
                    <div
                      style={{ background: '#F8FAFB' }}
                      className={classes.noteBox}
                    >
                      {/*<p className={classes.noteTitle}>*/}
                      {/*  {inputs?.subject}*/}
                      {/*</p>*/}
                      <p className={classes.noteInfo}>
                        {moment.utc().format('LT')}
                      </p>
                    </div>
                  }
                  {!!notes?.length &&
                    <div className={classes.reverse}>
                      {notes?.map((i, j) => (
                        <div
                          style={
                            selectedNote?.id === i?.id ?
                              {
                                background: '#F7F9FC',
                                border: '1px solid #438AFE',
                              }
                              :
                              {
                                background: 'transparent',
                                border: '1px solid #E6ECF3',
                              }
                          }
                          className={classes.noteBox} key={j} onClick={() => handleSelectNote(i)}
                        >
                          {/*<p style={{ color: selectedNote?.id === i?.id ? '#438AFE' : '#172B4D' }} className={classes.noteTitle}>{i?.subject}</p>*/}
                          <p className={classes.noteSubject}>
                            {i?.text}
                          </p>

                          <div className={classes.noteInfo}
                               style={{ borderTop: '1px solid #E6ECF3', paddingTop: 8 }}
                          >
                            <div className="flex" style={{ gap: 8 }}>
                              <p>
                                {moment.utc(i?.createdDate).format('MM/DD/YYYY')}
                              </p>
                              <p>
                                {moment(i?.createdDate).format('LT')}
                              </p>
                            </div>

                            <div style={{ width: '180px' }}>
                              {i?.user?.firstName &&
                                <TextRow name={`by ${i?.user?.firstName} ${i?.user?.lastName}`} textWidth={7} />
                              }
                            </div>

                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              }

              <div style={{ width: '100%' }}>
                {noteLeftSide()}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};