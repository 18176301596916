import React from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { inputsStyle } from './styles';
import { Colors, FindLoad } from '../../utils';
import { TextRow } from '../table';
import { ErrMessage } from '../messages';
import { InputMinLoader } from './inputMiniLoader';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultipleCheckBox = ({
                                 label,
                                 permissionsList,
                                 placeholder,
                                 handleChange,
                                 styles,
                                 typeError,
                                 noChip,
                                 name,
                                 value,
                                 disabled,
                                 renderValue,
                                 inputType,
                                 uiType,
                                 outLabel,
                                 CustomPaperComponent,
                                 notShowError, textRow, removeWhenClose,loadType
                               }) => {
  const classes = inputsStyle();
  const classname = !noChip ? classes.noChip : '';
  const loader = FindLoad(loadType);


  const inputStyle =
    inputType === 'serviceType' ?
      classes.serviceTypeAutocompleteStyle :
      classes.autocompleteStyle;

  return (
    <div>
      {outLabel && <p className={classes.labelTitle}>{outLabel}</p>}
      <>
        <Autocomplete
          loading={!!loader?.length}
          onClose={() => removeWhenClose && handleChange([])}
          disabled={disabled}
          className={uiType === 'tableFilter' ? classes.noChipLoads :
            uiType === 'defaultCheckboxSelect' ? classes.inputTextFieldCheckboxAuto :
              uiType === 'modalCheckboxSelect' ? classes.inputTextFieldCheckboxes :
                `${inputStyle} ${classname}`
          }
          multiple
          value={value}
          defaultValue={value}
          style={styles}
          id="checkboxes-tags-demo"
          key={permissionsList}
          options={permissionsList}
          disableCloseOnSelect
          getOptionSelected={(option, value) => option?.id === value?.id}
          onChange={(event, value) => handleChange(value)}
          getOptionLabel={(option) => renderValue ? renderValue(option) : option.title}
          renderOption={(option, { selected }) => {
            const lastElement = option?.id === permissionsList[permissionsList.length - 1]?.id;
            return (
              <div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8, color: Colors.ThemeBlue }}
                    checked={selected}
                  />
                  <div style={{ width: '100%' }}>
                    <p style={{ width: '100%' }}>
                      {!textRow ?
                        renderValue ? renderValue(option) : option.title
                        :
                        <TextRow name={renderValue ? renderValue(option) : option.title}
                                 textWidth={8} />
                      }
                    </p>
                    {name === 'permissions' &&
                      <p style={{ color: 'gray', fontSize: '12px' }}>  {option?.description}</p>
                    }
                  </div>
                </div>

                {lastElement && CustomPaperComponent &&
                  <div>
                    {CustomPaperComponent}
                  </div>
                }
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              InputProps={!!loader?.length ? {
                ...params.InputProps,
                endAdornment: (
                  <InputMinLoader />
                ),
              } : { ...params.InputProps }}
              {...params}
              error={typeError}
              className={classes.inputTextFieldAutoHeight}
              variant="outlined"
              label={label}
              placeholder={placeholder}
            />
          )}
          // PopperComponent={CustomPaperComponent}
        />
        {!notShowError &&
          <ErrMessage
            text={typeError}
          />
        }
      </>
    </div>
  );
};
